import React from "react"
import styled, { useTheme } from "styled-components"
import media from "@styles/media"
import PropTypes from "prop-types"
import Breadcrumb from "@components/blog/breadcrumb"
import Heading from "@components/elements/heading"
import Grid from "@components/elements/grid"
import Container from "@components/layout/container"
import Image from "@components/image"
import Div from "@components/elements/div"
import Paragraph from "@components/elements/paragraph"
import { graphql, useStaticQuery } from "gatsby"
import GalleryCta from "@components/cta/gallery-cta"
import Html from "@components/html"

const Column = styled.div`
  display: flex;
  flex-flow: column;
  align-self: center;
`

const DashboardWrapper = styled.div`
  background: ${({ hasCta }) =>
    hasCta
      ? "linear-gradient(rgba(255, 255, 255, 0) 0%, rgb(242 244 246) 73%, rgba(255, 255, 255, 0) 73%);"
      : "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FAFAFF 100%)"};
  width: 100%;
  overflow: hidden;

  ${props =>
    props.shadow &&
    `
    .klipfolio-image-wrapper {
      div {
        box-shadow: 0px 0px 2rem rgba(0, 0, 0, 0.15);
      }
    }
  `}
`

const SecondColumn = styled.div`
  margin-top: 2rem;

  ${media.sm`
    grid-row: 1;
    margin-bottom: 2rem;
    margin-top: 0;
  `}

  ${media.md`
    margin-top: 0;
    margin-bottom: 2rem;
    grid-row: 1;
    ${props =>
      props.relative &&
      `
      display: none;
    `}
  `}

  ${props =>
    props.relative &&
    `
    position: relative;
    height: 100%;
  `}
`

const OverflowGrid = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: fit-content(24rem);
  position: absolute;
  left: 0;
  margin-top: 0;
  gap: 1rem;
  max-width: 1500px;
  width: 1200px;
  align-items: start;
`

const ExampleScreenshot = styled.div`
  position: relative;
  object-fit: cover;
  overflow: hidden;
  border-radius: 1rem;
  z-index: 1;
  box-shadow: 0px 64px 64px 0px rgb(160 180 200 / 40%),
    0px 32px 32px 0px rgb(160 180 200 / 60%),
    0px 8px 8px 0px rgb(160 180 200 / 80%), 0px 2px 2px 0px #a0b4c8;
`

const GalleryHeader = ({
  name,
  cta,
  subtitle,
  subtitleHTML,
  image,
  links,
  link,
  services,
}) => {
  const { color } = useTheme()
  const { images } = useStaticQuery(graphql`
    query GalleryHeader {
      images: allDirectusFiles(
        filter: {
          directus_id: {
            in: [
              "a10016cf-a7fd-401a-82b9-cd2d4ca5e0e2"
              "c06f41e4-f85e-4666-899d-49aa82716109"
              "14bb8d50-2382-419c-9664-0243d01fdbd7"
              "bb6dab93-0464-486c-a4b0-5dc15a11f807"
              "48671155-ab59-47a9-851f-c07ca37bf883"
              "24a8b083-586c-49bd-a5ad-9e405184bc74"
            ]
          }
        }
      ) {
        edges {
          node {
            ...imageData
          }
        }
      }
    }
  `)

  return (
    <DashboardWrapper shadow hasCta>
      <Container>
        <Div margin="2rem 0 4rem" marginSm="2rem 0">
          <Breadcrumb noPadding={true} links={links} />
          <Grid
            alignItems="start"
            gap="4rem"
            margin="0"
            marginMd="2rem 0 0"
            marginSm="2rem 0 0"
            gapMd="0"
            gapSm="0"
            columns={"1fr 1fr"}
            columnsMd="1fr"
            columnsSm="1fr"
          >
            <Column>
              <header>
                <Heading
                  itemProp={"headline"}
                  fontSize="4rem"
                  margin="1rem 0"
                  mobileScale={0.6}
                  color={color.blue600}
                >
                  {name}
                </Heading>
                {subtitle && (
                  <Paragraph
                    fontWeight="600"
                    fontSize="1.3rem"
                    lineHeight="1.5"
                    margin="0 0 2rem"
                    letterSpacing="-0.03rem"
                    mobileScale={0.9}
                    color={color.blue600}
                  >
                    {subtitle}
                  </Paragraph>
                )}
                {subtitleHTML && (
                  <Div margin="0 0 2rem">
                    <Html
                      styles={{
                        paragraph: {
                          color: color.blue600,
                          fontSize: "1.3rem",
                          lineHeight: 1.5,
                          fontWeight: 600,
                        },
                      }}
                      parseElements
                      html={subtitleHTML}
                    />
                  </Div>
                )}
              </header>
              <GalleryCta name={name} cta={cta} link={link} services={services} />
            </Column>
            <SecondColumn relative={image ? false : true}>
              {image ? (
                <Image radius={1} eager showOverflow file={image} />
              ) : (
                <OverflowGrid>
                  {images?.edges?.map(({ node: image }, i) => {
                    return (
                      <ExampleScreenshot key={`ExampleScreenshot-${i}`}>
                        <Image file={image} eager radius={1} showOverflow />
                      </ExampleScreenshot>
                    )
                  })}
                </OverflowGrid>
              )}
            </SecondColumn>
          </Grid>
        </Div>
      </Container>
    </DashboardWrapper>
  )
}

GalleryHeader.propTypes = {
  name: PropTypes.string.isRequired,
  cta: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  subtitleHTML: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  links: PropTypes.array.isRequired,
  link: PropTypes.string.isRequired,
  services: PropTypes.array.isRequired,
}

export default GalleryHeader
