import Button from "@components/elements/button"
import React from "react"
import styled, { useTheme } from "styled-components"
import PropTypes from "prop-types"
import Heading from "@components/elements/heading"
import Span from "@components/elements/span"
import Flex from "@components/elements/flex"
import { useCta } from "@hooks/use-cta"
import Paragraph from "@components/elements/paragraph"
import Anchor from "@components/elements/anchor"
import Image from "@components/image"
import Div from "@components/elements/div"

const StyledButtonLine = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  opacity: 0.4;
  font-weight: bold;
  color: white;
  line-height: 22px;
  margin: 11px 0;
  ::after,
  ::before {
    content: " ";
    transform: translateY(2px);
    opacity: 0.4;
    height: 2px;
    flex: 1;
    background-color: white;
  }
`

const GalleryCta = ({ name, cta, services, link }) => {
  const { color, shadow } = useTheme()
  const { ctaLink } = useCta(false, null, null)

  return (
    <Flex
      maxWidth="450px"
      background={color.blue600}
      id="galleryCta"
      borderRadius="2rem"
      padding="2rem"
      width="100%"
      margin="0"
      boxShadow={shadow.default}
    >
      {services && (
        <Flex margin="0 0 1rem" flexFlow="row" gap="0.5rem">
          {services.map((service, i) => (
            <Anchor
              key={`service-${i}`}
              to={service.just_docs ? service.docs : `/integrations/${service.slug}`}
              fontSize="0.9rem"
              color="#666"
            >
              <Div padding={"0.5rem"} background="white" borderRadius="100%">
                <Image
                  file={service.logo}
                  height={32}
                  width={32}
                  objectFit="scale-down"
                />
              </Div>
            </Anchor>
          ))}
        </Flex>
      )}
      <Heading margin="0 0 1rem" as="h4" color="white">
        {cta ? cta : `Track all your ${services ? services[0].name : name} KPIs in one place`}
      </Heading>

      <Paragraph fontSize="1rem" color="white" margin="0 0 1.5rem">
        {services ? (
          <>
            Sign up for <b>free</b> and get started with our {name} Klip.
          </>
        ) : (
          <>
            Sign up for <b>free</b> and start making decisions for your business
            with confidence.
          </>
        )}
      </Paragraph>
      {link ? (
        <Button
          width="100%"
          data-cta-location="header"
          href={link}
          background={color.blue400}
          center
          notice={false}
        >
          Get This Klip +
        </Button>
      ) : (
        <>
          <Button.Google data-cta-location="header" notice={false} />
          <StyledButtonLine>or</StyledButtonLine>
          <Button
            data-cta-location="header"
            href={ctaLink}
            background={color.blue400}
            center
            notice={false}
          >
            Sign up with your email
          </Button>
        </>
      )}
      <Span
        fontWeight={600}
        fontSize="0.7rem"
        margin="0.5rem auto 0"
        color="white"
        opacity={0.4}
      >
        Free for 14 days ● No credit card required
      </Span>
    </Flex>
  )
}

GalleryCta.propTypes = {
  name: PropTypes.string,
  cta: PropTypes.string,
  services: PropTypes.array,
  link: PropTypes.string,
}

export default GalleryCta
